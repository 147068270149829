<!--
 * @Author: your name
 * @Date: 2021-04-09 16:24:16
 * @LastEditTime: 2022-02-22 18:37:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\productionManagement\industrialControlAdministration\updatePrintingTime.vue
-->
<template>
    <div class="updatePrintingTime">
        <div class="block">
            <span class="demonstration">选择时间：</span>
            <el-date-picker
                v-model="time"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
        </div>
        <footer>
            <el-button @click="cancel">
                取消
            </el-button>
            <el-button type="primary" @click="confirm">
                确认
            </el-button>
        </footer>
    </div>
</template>

<script>
import util from '@/utils/util';

export default {
    // 更新打料时间[工控管理-接收调度指令-打印(济宁)]
    name: 'update-printing-time',
    components: {},
    props: {
        extr: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            time: '',
        };
    },
    computed: {},
    watch: {},
    created() {
        this.time = util.currentTimeFormat('yyyy-MM-dd HH:mm:ss');
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 关闭弹出层
        cancel() {
            this.$parent.hide();
        },
        // 确认
        confirm() {
            this.$axios
                .put(`/interfaceApi/production/productpcb/filled/${this.extr.pcb_number}/${this.time}`)
                .then(() => {
                    this.extr.table.updateTable();
                    this.printing();
                })
                .catch(error => {
                    this.$parent.hide();
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 打印
        printing() {
            this.$axios
                .get('/interfaceApi/production/productpcb/print/' + this.extr.pcb_number)
                .then(res => {
                    if (res) {
                        this.extr.table.$printPreview(res.template_code, res.data);
                    }
                    this.$parent.hide();
                }).catch(error => {
                    this.$parent.hide();
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
.updatePrintingTime
    height 100%
    padding 0.4rem
    .block
        width 100%
        display flex
        .demonstration
            width 1rem
            line-height 0.36rem
            text-align right
        .el-date-editor
            width calc(100% - 1rem) !important
    footer
        position absolute
        bottom 0.3rem
        left 0
        width 100%
        text-align center
        button
            width 1.4rem
            margin 0 0.2rem
</style>
